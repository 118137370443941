import request from './base/request';

export const getListBranchApi = (params) => {
  return request
    .get('/branch', { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const createBranchApi = (data) => {
  return request
    .post('/branch', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateBranchApi = (data) => {
  return request
    .put('/branch', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateBranchLayerApi = async(data) => {
  return await request.put('/branch-attribute', data);
};

export const getListOrganizationalApi = () => {
  return request
    .get('/branch/list-type')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const updateBranchAttributeApi = (data) => {
  return request
    .post('/branch-attribute', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}
